import { external } from '@emobg/web-api-client';

import {
  addCompanyNewPaymentMethod,
  addUserNewPaymentMethod,
  getCompanyPaymentMethodsCollection,
  getUserPaymentMethodsCollection,
  postCompanyTariffsPayment,
  postUserTariffsPayment,
  removeCompanyPaymentMethod,
  removeUserPaymentMethod,
  setCompanyDefaultPaymentMethod,
  setUserDefaultPaymentMethod,
} from './paymentMethodsApi.js';

jest.mock('@emobg/web-api-client', () => ({
  external: {
    paymentsUser: {
      getUserPaymentMethods: jest.fn(),
      postPaymentMethodPSD2: jest.fn(),
      putLinkPaymentMethodToUser: jest.fn(),
      putRemovePaymentMethod: jest.fn(),
      postTariffsPayment: jest.fn(),
    },
    paymentsCompany: {
      postCompanyPaymentMethod: jest.fn(),
      getCompanyPaymentMethods: jest.fn(),
      putSetDefaultPaymentMethod: jest.fn(),
      putRemovePaymentMethod: jest.fn(),
      postTariffsPayment: jest.fn(),
    },
  },
}));

describe('Given paymentMethodsApi', () => {
  beforeEach(() => {
    jest.resetModules();
  });

  afterEach(() => {
    jest.resetAllMocks();
  });

  describe('Given the "getUserPaymentMethodsCollection" method', () => {
    describe('When is called with the "uuid-12345" uuid', () => {
      it('Then the "getUserPaymentMethods" method from "external.paymentsUser" is called with "uuid-12345"', () => {
        const getUserPaymentMethodsSpy = jest.spyOn(external.paymentsUser, 'getUserPaymentMethods');

        getUserPaymentMethodsCollection('uuid-12345');

        expect(getUserPaymentMethodsSpy).toBeCalledWith('uuid-12345');
      });
    });
  });

  describe('Given the "addUserNewPaymentMethod" method', () => {
    describe('When is called with the "uuid-12345" uuid and the payload "payload"', () => {
      it('Then the "postPaymentMethodPSD2" method from "external.paymentsUser" is called with "uuid-12345" and "payload"', () => {
        const postPaymentMethodPSD2Spy = jest.spyOn(external.paymentsUser, 'postPaymentMethodPSD2');

        addUserNewPaymentMethod('uuid-12345', 'payload');

        expect(postPaymentMethodPSD2Spy).toBeCalledWith('uuid-12345', 'payload');
      });
    });
  });

  describe('Given the "setUserDefaultPaymentMethod" method', () => {
    describe('When is called with the "uuid-12345" user uuid and the payment method uuid "uuid-pm-1"', () => {
      it('Then the "putLinkPaymentMethodToUser" method from "external.paymentsUser" is called with "uuid-12345" and "uuid-pm-1"', () => {
        const putLinkPaymentMethodToUserSpy = jest.spyOn(external.paymentsUser, 'putLinkPaymentMethodToUser');

        setUserDefaultPaymentMethod('uuid-12345', {
          user_payment_method_uuid: 'uuid-pm-1',
        });

        expect(putLinkPaymentMethodToUserSpy).toBeCalledWith('uuid-12345', {
          user_payment_method_uuid: 'uuid-pm-1',
        }, 'v3');
      });
    });
  });

  describe('Given the "removeUserPaymentMethod" method', () => {
    describe('When is called with the "uuid-12345" user uuid and the payment method uuid "uuid-pm-1"', () => {
      it('Then the "putRemovePaymentMethod" method from "external.paymentsUser" is called with "uuid-12345" and "uuid-pm-1"', () => {
        const putRemovePaymentMethodSpy = jest.spyOn(external.paymentsUser, 'putRemovePaymentMethod');

        removeUserPaymentMethod('uuid-12345', {
          user_payment_method_uuid: 'uuid-pm-1',
        });

        expect(putRemovePaymentMethodSpy).toBeCalledWith('uuid-12345', {
          user_payment_method_uuid: 'uuid-pm-1',
        });
      });
    });
  });

  describe('Given the "getCompanyPaymentMethodsCollection" method', () => {
    describe('When is called with the "uuid-12345" uuid', () => {
      it('Then the "getCompanyPaymentMethods" method from "external.paymentsCompany" is called with "uuid-12345"', () => {
        const getCompanyPaymentMethodsSpy = jest.spyOn(external.paymentsCompany, 'getCompanyPaymentMethods');

        getCompanyPaymentMethodsCollection('uuid-12345');

        expect(getCompanyPaymentMethodsSpy).toBeCalledWith('uuid-12345');
      });
    });
  });

  describe('Given the "addCompanyNewPaymentMethod" method', () => {
    describe('When is called with the "uuid-12345" uuid and the payload "payload"', () => {
      it('Then the "postCompanyPaymentMethod" method from "external.paymentsCompany" is called with "uuid-12345" and "payload"', () => {
        const postCompanyPaymentMethodSpy = jest.spyOn(external.paymentsCompany, 'postCompanyPaymentMethod');

        addCompanyNewPaymentMethod('uuid-12345', 'payload');

        expect(postCompanyPaymentMethodSpy).toBeCalledWith('uuid-12345', 'payload');
      });
    });
  });

  describe('Given the "setCompanyDefaultPaymentMethod" method', () => {
    describe('When is called with the "uuid-12345" company uuid and the payment method uuid "uuid-pm-1" and the user uuid "uuid-user-1"', () => {
      it('Then the "putSetDefaultPaymentMethod" method from "external.paymentsCompany" is called with "uuid-12345" "uuid-pm-1" and "uuid-user-1"', () => {
        const putSetDefaultPaymentMethodSpy = jest.spyOn(external.paymentsCompany, 'putSetDefaultPaymentMethod');

        setCompanyDefaultPaymentMethod('uuid-12345', 'uuid-pm-1', 'uuid-user-1');

        expect(putSetDefaultPaymentMethodSpy).toBeCalledWith('uuid-12345', 'uuid-pm-1', 'uuid-user-1');
      });
    });
  });

  describe('Given the "removeCompanyPaymentMethod" method', () => {
    describe('When is called with the "uuid-12345" company uuid and the payment method uuid "uuid-pm-1" and the user uuid "uuid-user-1"', () => {
      it('Then the "putRemovePaymentMethod" method from "external.paymentsCompany" is called with "uuid-12345" "uuid-pm-1" and "uuid-user-1"', () => {
        const putRemovePaymentMethodSpy = jest.spyOn(external.paymentsCompany, 'putRemovePaymentMethod');

        const payload = {
          company_payment_method_uuid: 'uuid-pm-1',
          user_uuid: 'uuid-user-1',
        };

        removeCompanyPaymentMethod('uuid-12345', payload);

        expect(putRemovePaymentMethodSpy).toBeCalledWith('uuid-12345', {
          company_payment_method_uuid: 'uuid-pm-1',
          user_uuid: 'uuid-user-1',
        });
      });
    });
  });

  describe('Given the "postUserTariffsPayment" method', () => {
    describe('When is called with "tariff-uuid-1" and "payload"', () => {
      it('Then the "postTariffsPayment" from "external.paymentsUser" is called', () => {
        const postTariffsPaymentSpy = jest.spyOn(external.paymentsUser, 'postTariffsPayment');

        postUserTariffsPayment('tariff-uuid-1', 'payload');

        expect(postTariffsPaymentSpy).toHaveBeenCalledWith('tariff-uuid-1', 'payload');
      });
    });
  });

  describe('Given the "postCompanyTariffsPayment" method', () => {
    describe('When is called with "tariff-uuid-2" and "payload"', () => {
      it('Then the "postTariffsPayment" from "external.paymentsCompany" is called', () => {
        const postTariffsPaymentSpy = jest.spyOn(external.paymentsCompany, 'postTariffsPayment');

        postCompanyTariffsPayment('tariff-uuid-2', 'payload');

        expect(postTariffsPaymentSpy).toHaveBeenCalledWith('tariff-uuid-2', 'payload');
      });
    });
  });
});
